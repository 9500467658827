import React from 'react';
import { Link } from 'gatsby';

export const CaseStudyCard = ({ data }) => {
  const { featuredImage, client, slug, title } = data;
  return (
    <div className="article__card rel">
      {featuredImage && (
        <div
          className="article__card-image mb05"
          style={{
            backgroundImage:
              'url(' +
              featuredImage.fields.file.url +
              '?fm=jpg&q=80&w=800&fl=progressive)',
          }}
        />
      )}
      <Link className="abs x y top left" to={`/case-study/${slug}`} />
      <div className="article__card_contents f jcb fw">
        <h4 className="m0 py05" dangerouslySetInnerHTML={{ __html: title }} />
        <h6 className="x caps m0">Client</h6>
        <h6 className="m0 mt05">{client}</h6>
      </div>
      <hr />
      <div className="f jcb aic mt05 mb1 pb05"></div>
    </div>
  );
};
