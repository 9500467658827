import React from 'react';
import { chunk, filter } from 'lodash';
import Helmet from 'react-helmet';
import vsbl from 'vsbl';

import { initGA, logPageView } from '../components/layout/google.js';
import { initFB, logFBPageView } from '../components/layout/facebook.js';

import StaticShare from '../../static/spaceagency_share_img.jpg';

import FeaturedArticles from '../components/editorial/featured-articles.js';
import { CaseStudyCard } from '../components/editorial/case-study-card.js';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.cleanArticles = filter(this.props.pageContext.articles, article => {
      const articleClean = JSON.parse(article.node.internal.content);
      return articleClean.hideFromIndex !== true;
    });

    this.state = {
      stateArticles: chunk(this.cleanArticles, 39),
      postsPerPage: 39,
      activePage: 0,
      filter: null,
      fakePage: 1,
      allArticles: this.cleanArticles,
      totalItems: this.cleanArticles.length,
    };
    this.resetFilters = this.resetFilters.bind(this);
    this.load = React.createRef();
  }

  componentDidMount() {
    initGA();
    logPageView();
    initFB();
    logFBPageView();

    const totalPages = this.state.totalItems / this.state.postsPerPage;
    this.vs = vsbl(this.load.current)(() => {
      if (totalPages < this.state.fakePage) return;

      this.loadMore(this.state.fakePage + 1);
    });

    this.vs.update(); // on page load
  }

  loadMore(page) {
    if (this.state.filter && this.state.allFilteredArticles) {
      this.setState({
        stateArticles: chunk(
          this.state.allFilteredArticles,
          this.state.postsPerPage + page * this.state.postsPerPage
        ),
        fakePage: page,
      });
    } else {
      this.setState({
        stateArticles: chunk(
          this.state.allArticles,
          this.state.postsPerPage + page * this.state.postsPerPage
        ),
        fakePage: page,
      });
    }
  }

  morePosts() {
    // check if there are more posts
    const totalPages = this.state.totalItems / this.state.postsPerPage;
    if (totalPages > this.state.fakePage) {
      return (
        <div
          className="caps small py1 my1 btn btn__blue"
          onClick={() => this.loadMore(this.state.fakePage + 1)}
        >
          Load More
        </div>
      );
    } else {
      return <span />;
    }
  }

  filterArticles(type) {
    const filtered = filter(this.cleanArticles, article => {
      const articleClean = JSON.parse(article.node.internal.content);
      return articleClean.type === type;
    });
    this.setState({
      stateArticles: chunk(filtered, this.state.postsPerPage),
      activePage: 0,
      fakePage: 1,
      totalItems: filtered.length,
      filter: type,
      allFilteredArticles: filtered,
    });
  }

  resetFilters() {
    this.setState({
      stateArticles: chunk(this.state.allArticles, this.state.postsPerPage),
      fakePage: 1,
      totalItems: this.state.totalItems,
      filter: null,
    });
  }

  render() {
    const { articles } = this.props.pageContext;
    const { stateArticles, activePage, filter } = this.state;
    return (
      <div>
        <Helmet title="SpaceAgency">
          <html lang="en" />
          <meta
            name="description"
            content="SpaceAgency’s mission is to tell the human side of our greatest outer space stories. With films, podcasts, artwork, events, and applications, we’re exploring amazing milestones from our past and the wildest ideas that drive our future."
          />
          <meta name="og:url" content="https://spaceagency.supercluster.com" />
          <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
          <meta
            property="og:image"
            content="https://spaceagency.supercluster.com/spaceagency_share_img.jpg"
          />
          <meta
            property="og:description"
            content="SpaceAgency’s mission is to tell the human side of our greatest outer space stories. With films, podcasts, artwork, events, and applications, we’re exploring amazing milestones from our past and the wildest ideas that drive our future."
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="SpaceAgency" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="SpaceAgency" />
          <meta
            name="twitter:description"
            content="SpaceAgency’s mission is to tell the human side of our greatest outer space stories. With films, podcasts, artwork, events, and applications, we’re exploring amazing milestones from our past and the wildest ideas that drive our future."
          />
          <meta name="twitter:url" content="https://spaceagency.supercluster.com" />
        </Helmet>
        <div className="container--xl ma px15 pb2 pt1">
          <h1>
            A creative studio <br className="show--s" />
            built for the New Space Age. <br className="show--s" />
          </h1>
          <span className="arrow__down ml075 block cw rel" />
        </div>
        <div>
          <FeaturedArticles articles={articles.slice(0, 3)} />
        </div>
        <div className="al">
          <div className="container--xl ma p15">
            <h6 className="caps pb0">Projects</h6>
            <div className="">
              {stateArticles.length ? (
                <div className="f jcb ais fw">
                  {stateArticles[activePage].map(article => (
                    <CaseStudyCard
                      key={Math.random()}
                      data={JSON.parse(article.node.internal.content)}
                    />
                  ))}
                  <div className="article__card" />
                </div>
              ) : (
                <div className="article__filters-empty ac h3 p15 f jcc aic">
                  {`We're sorry there's currently nothing posted in ${this.state.filter}`}
                </div>
              )}
            </div>
            <div className="x jcs f ais" ref={this.load}>
              {this.morePosts()}
            </div>
          </div>
        </div>
        <div className="x bcw height-1" />
      </div>
    );
  }
}

export default IndexPage;
