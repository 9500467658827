import React from 'react';
import { Link } from 'gatsby';

class FeaturedArticles extends React.Component {
  constructor(props) {
    super();
    this.state = {
      slide: 0,
      flky: null,
    };
    this.slideshow = React.createRef();
  }

  componentDidMount() {
    require('flickity-bg-lazyload');
    const Flickity = require('flickity');
    const flky = new Flickity(this.slideshow.current, {
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      bgLazyLoad: true,
      autoPlay: 4000,
      wrapAround: true,
    });
    this.setState({
      flky: flky,
    });
    flky.on('change', index => {
      this.setState({
        slide: index,
      });
    });
  }

  render() {
    const { articles } = this.props;
    return (
      <div className="rel">
        <div className="rel">
          <div className="featured__slideshow index" ref={this.slideshow}>
            {articles.map((article, i) => {
              const cleanUp = JSON.parse(article.node.internal.content);
              return (
                <div
                  key={i}
                  className="featured__slideshow-slide x"
                  data-flickity-bg-lazyload={`${cleanUp.featuredImage.fields.file.url}?fm=jpg&q=80&fl=progressive`}
                >
                  <Link
                    className="abs x y top left"
                    to={`/case-study/${cleanUp.slug}`}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="featured__previous abs left f jcc aic"
            onClick={() => this.state.flky.previous()}
          >
            <span className="arrow__right arrow ml05 block cw rel" />
          </div>
          <div
            className="featured__next abs right f jcc aic"
            onClick={() => this.state.flky.next()}
          >
            <span className="arrow__right arrow ml05 block cw rel" />
          </div>
          <div className="featured__dots abs bottom f jcc aic">
            {articles.map((article, i) => (
              <div
                key={i}
                className={`__dot m05 ${this.state.slide === i ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className="featured__headlines bcg">
          <div className="featured__headlines_block container--xl ma z1 rel bcg p15">
            {articles.map((article, i) => {
              const cleanUp = JSON.parse(article.node.internal.content);
              return (
                <div
                  key={i}
                  className={`featured__headlines-single x rel ${
                    this.state.slide === i ? 'active' : ''
                  }`}
                >
                  <Link
                    className="abs x y top left"
                    to={`/case-study/${cleanUp.slug}`}
                  />
                  <div className="mt05" />
                  <div className="f jcb fw ais featured__headlines-flex">
                    <div className="x">
                      <h1
                        className="p0 mb05 mt0 featured__headlines_header"
                        dangerouslySetInnerHTML={{ __html: cleanUp.title }}
                      />
                      <h6 className="caps xsmall m0 mb05 p0 featured__tags">
                        Client: {cleanUp.client}
                      </h6>
                    </div>
                    <div className="x">
                      <h6 className="caps mt1 mb1 p0 f aic">
                        {'VIEW CASE STUDY '}
                        <span className="arrow__right arrow ml05 block cw rel" />
                      </h6>
                      <p className="s1">{cleanUp.cardPreviewSnippet}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturedArticles;
